import { useState } from "react";
import { deserialize, serialize } from "serializr";
import { NotificationTypes } from "../../enums/notificationTypes";
import axiosInstance from "../../interceptor/axiosInstance";
import { PaginationMeta } from "../../models/pagination.model";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import Notification from "../../shared/components/Notification";
import { CreditReportParams, Loan, LoanParams, LoanReportParams } from "../../models/loan.model";
import { generatePath } from "react-router-dom";
import { DisbursementModel } from "../../models/disbursement.model";
import { generateFileName } from "../../shared/utils/generateFileName";
import {
  LoanTypeConditionModel,
  LoanTypeDetailsModel,
  LoanTypeConfigurationModel,
} from "../../models/loanType.model";

import {
  LoanRepayamentSchedule,
  LoanRepayamentScheduleParams,
} from "../../models/loanRepaymentSchedule";
import { LoanTimeline, LoanTimelineParams } from "../../models/loanTimeline";
import { LoanWalletParams, LoanWallet } from "../../models/loanWallet.model";
import { FileAttachment } from "../../models/fileAttachment.model";
import {
  LoanInstallmentParams,
  LoanInstallmentSchedule,
} from "../../models/loanInstallmentSchedule.model";
import { MoratoriumLoan } from "../../models/Moratorium.model";
import { WriteOffLoan } from "../../models/WriteOffLoan.model";
import {
  TransferLoans,
  TransferLoansParams,
} from "../../models/transferLoans.model";
import { LoanDeductionForm } from "../../models/loanDeduction.model";
import { NachReport } from "../../models/nachReport";
import { NachType } from "../../enums/nachType";
import { downloadBlobAsFile } from "../../shared/utils/downloadBlobAsFile";
import { LoanEMI, LoanEMIParams } from "../../models/loanEMI.model";
import { LoanAgreement } from "../../models/loanAgreement.model";
import { ChangePreferredChannel } from "../../models/changePreferredChannel.model";
import { UgroLoanDocument, UgroLoanDocuments } from "../../models/ugroDocument.model";
import { CrifReport } from "../../models/creditReport.model";
import { CreditScore } from "../../models/creditScore.model";
import { CrifFetchStatus } from "../../enums/crifStatus.type";
import { Hypothecator, HypothecatorLoans } from "../../models/bookDebt.model";

export const LoanService = () => {
  const [loading, setLoading] = useState(false);
  const [repaymentPagination, setRepaymentPagination] =
    useState<PaginationMeta>();
  const [loanSchedule, setLoanSchedule] = useState<LoanRepayamentSchedule[]>(
    []
  );
  const [loanLoading, setLoanLoading] = useState(false);
  const [templateLoading, setTemplateLoading] = useState(false);
  const [error, setError] = useState<Error>();

  const getLoans = async (params?: LoanParams) => {
    setLoading(true);
    try {
      const queryParams = params ? serialize(LoanParams, params) : {};
      const { data } = await axiosInstance.get(ApiRoutes.LOANS, {
        params: queryParams,
      });
      const deserializedLoan = deserialize(Loan, data["loans"] as any[]);
      const deserializedLoanMeta = deserialize(PaginationMeta, data?.meta);

      return {
        loans: deserializedLoan,
        meta: deserializedLoanMeta,
      };
    } catch (ex) {
      Notification({
        message: "Unable to Get Loans",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to Get Loans details",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const getLoan = async (loanId: number) => {
    setLoading(true);
    try {
      const endpoint = generatePath(ApiRoutes.LOAN_SUMMARY, {
        loanId: String(loanId),
      });
      const { data } = await axiosInstance.get(endpoint);
      const deserializedLoan = deserialize(Loan, data["loan"]);

      return deserializedLoan;
    } catch (ex) {
      Notification({
        message: "Unable to Get Loans",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to Get Loans details",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const updateLoan = async (loanId: number, loan: Partial<Loan>) => {
    setLoading(true);
    try {
      const endpoint = generatePath(ApiRoutes.LOAN_DETAIL, {
        loanId: loanId.toString(),
      });
      const serializedLoan = serialize(Loan, loan);
      const { data } = await axiosInstance.put(endpoint, {
        loan: serializedLoan,
      });
      const deserializedLoan = deserialize(Loan, data?.["loan"]);
      Notification({
        message: "Loan Updated",
        type: NotificationTypes.SUCCESS,
      });

      return deserializedLoan;
    } catch (ex) {
      Notification({
        message: "Unable to Update Loan",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to Update Loan details",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };
  const approveLoan = async (loanId: string, creditScore: CreditScore) => {
    setLoanLoading(true);
    try {
      const serializedCreditScore = serialize(CreditScore, creditScore)
      const endpoint = generatePath(ApiRoutes.APPROVE_LOAN, {
        loanId: loanId,
      })
      const { data } = await axiosInstance.post(endpoint, {
        credit_score: serializedCreditScore
      }) 
      Notification({
        message: "Loan Verified and Approved",
        description: "",
        type: NotificationTypes.SUCCESS,
      });
      return true;
    } catch (ex) {
        Notification({
            message: "Unable to Approve Loan",
            description: (ex as Error).message,
            type: NotificationTypes.ERROR,
        });
        setError(ex as Error);
    } finally {
        setLoanLoading(false);
    }
};

  const rejectLoan = async (loanId: string, reason: string) => {
    setLoanLoading(true);
    try {
      const { data } = await axiosInstance.post(
        ApiRoutes.REJECT_LOAN.replace(":loanId", loanId),
        { rejection_reason: reason }
      );
      Notification({
        message: "Loan Rejected",
        description: "",
        type: NotificationTypes.SUCCESS,
      });
      return true;
    } catch (ex) {
      Notification({
        message: "Unable to Reject Loan",
        description: "",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoanLoading(false);
    }
  };

  const generateLoanReport = async (loanFilter?: LoanReportParams) => {
    setLoading(true);
    try {
      const generatedFileName = generateFileName(
        loanFilter?.templateName ?? ""
      );
      const updatedParams = { ...loanFilter, fileName: generatedFileName };
      const endpoint = generatePath(ApiRoutes.LOAN_REPORT);
      const params =
        updatedParams && serialize(LoanReportParams, updatedParams);
      const { data } = await axiosInstance.get(endpoint, { params });
      Notification({
        message: "Loan Generated and sent to Mail",

        type: NotificationTypes.SUCCESS,
      });
      return true;
    } catch (ex) {
      Notification({
        message: "Unable to Get Loans",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to Get Loans details",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const disburseLoanAmount = async (loanFilter: DisbursementModel) => {
    setLoading(true);
    try {
      const endpoint = generatePath(ApiRoutes.DISBURSE_AMOUNT);
      const params = serialize(DisbursementModel, loanFilter);
      const { data } = await axiosInstance.post(endpoint, {
        disburse_conditions: params,
      });
      Notification({
        message: "Loan Amount Disbursed",

        type: NotificationTypes.SUCCESS,
      });
      const deserializedLoan = deserialize(Loan, data["loans"] as any[]);
      const deserializedLoanMeta = deserialize(PaginationMeta, data?.meta);
      return {
        loans: deserializedLoan,
        meta: deserializedLoanMeta,
      };
    } catch (ex) {
      Notification({
        message: "Unable to Disburse Amount",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to Disburse Amount",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const initiateDisburseStatus = async (loanFilter: DisbursementModel) => {
    setLoading(true);
    try {
      const endpoint = generatePath(ApiRoutes.INITIATE_DISBURSE_STATUS);
      const params = serialize(DisbursementModel, loanFilter);
      const { data } = await axiosInstance.get(endpoint, {
        params,
      });
      Notification({
        message: "Disbursement in Progress",

        type: NotificationTypes.SUCCESS,
      });
      const deserializedLoan = deserialize(Loan, data["loans"] as any[]);
      const deserializedLoanMeta = deserialize(PaginationMeta, data?.meta);
      return {
        loans: deserializedLoan,
        meta: deserializedLoanMeta,
      };
    } catch (ex) {
      Notification({
        message: "Unable to disburse",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to disburse ",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };
  const completeDisburseStatus = async (loanFilter: DisbursementModel) => {
    setLoading(true);
    try {
      const endpoint = generatePath(ApiRoutes.COMPLETE_DISBURSE_STATUS);
      const params = serialize(DisbursementModel, loanFilter);
      const { data } = await axiosInstance.get(endpoint, {
        params,
      });
      Notification({
        message: "Status Changed to Disbursed",

        type: NotificationTypes.SUCCESS,
      });
      const deserializedLoan = deserialize(Loan, data["loans"] as any[]);
      const deserializedLoanMeta = deserialize(PaginationMeta, data?.meta);
      return {
        loans: deserializedLoan,
        meta: deserializedLoanMeta,
      };
    } catch (ex) {
      Notification({
        message: "Unable to Change Status",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to Change Status ",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };
  const revertDisburseStatus = async (loanFilter: DisbursementModel) => {
    setLoading(true);
    try {
      const endpoint = generatePath(ApiRoutes.REVERT_DISBURSE_STATUS);
      const params = serialize(DisbursementModel, loanFilter);
      const { data } = await axiosInstance.get(endpoint, { params });
      Notification({
        message: "Status Changed to Approved",

        type: NotificationTypes.SUCCESS,
      });
      const deserializedLoan = deserialize(Loan, data["loans"] as any[]);
      const deserializedLoanMeta = deserialize(PaginationMeta, data?.meta);
      return {
        loans: deserializedLoan,
        meta: deserializedLoanMeta,
      };
    } catch (ex) {
      Notification({
        message: "Unable to Change Status",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to Change Status ",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const getLoanTypeDetails = async (loanTypeId: string) => {
    setLoading(true);
    try {
      const endpoint = generatePath(ApiRoutes.LOAN_TYPE_DETAILS, {
        loanTypeId: loanTypeId,
      });
      const { data } = await axiosInstance.get(endpoint);

      const deserializedLoanType = deserialize(
        LoanTypeDetailsModel,
        data["loan_type"]
      );
      return deserializedLoanType;
    } catch (ex) {
      Notification({
        message: "Unable to Get Loan Type Details",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to Get Loan Type Details",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };
  const getLoanTypeCondition = async (loanTypeId: string) => {
    setLoading(true);
    try {
      const endpoint = generatePath(ApiRoutes.LOAN_TYPE_CONDITON, {
        loanTypeId: loanTypeId,
      });
      const { data } = await axiosInstance.get(endpoint);

      const deserializedLoanConfig = deserialize(
        LoanTypeConditionModel,
        data["loan_type_conditions"] as any[]
      );
      return deserializedLoanConfig;
    } catch (ex) {
      Notification({
        message: "Unable to Get Loan Type Condition",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to Get Loan Type Condition",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };
  const createLoanTypeDetails = async (loan: LoanTypeDetailsModel) => {
    setLoading(true);
    try {
      const params = serialize(LoanTypeDetailsModel, loan);
      const endpoint = generatePath(ApiRoutes.LOAN_TYPE);
      const { data } = await axiosInstance.post(endpoint, {
        loan_type: params,
      });

      const deserializedLoan = deserialize(
        LoanTypeDetailsModel,
        data["loan_type"]
      );

      return deserializedLoan;
    } catch (ex) {
      Notification({
        message: "Unable to Set Loan Type ",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to Set Loan Type ",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };
  const updateLoanTypeCondition = async (
    loan: LoanTypeConditionModel,
    loanTypeId: string
  ) => {
    setLoading(true);
    try {
      const params = serialize(LoanTypeConditionModel, loan);
      const endpoint = generatePath(ApiRoutes.UPDATE_LOAN_TYPE_CONDITON, {
        loanTypeId: loanTypeId,
        id: loan?.id,
      });
      const { data } = await axiosInstance.put(endpoint, {
        loan_type_conditions: params,
      });

      const deserializedLoan = deserialize(
        LoanTypeConditionModel,
        data["loan_type_condition"]
      );

      return deserializedLoan;
    } catch (ex) {
      Notification({
        message: "Unable to Set Loan Type ",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to Set Loan Type ",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };
  const deleteLoanTypeCondition = async (loanTypeId: string, id: string) => {
    setLoading(true);
    try {
      const endpoint = generatePath(ApiRoutes.UPDATE_LOAN_TYPE_CONDITON, {
        loanTypeId: loanTypeId,
        id: id,
      });
      const { data } = await axiosInstance.delete(endpoint);

      return true;
    } catch (ex) {
      Notification({
        message: "Unable to Set Loan Type ",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to Set Loan Type ",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };
  const updateLoanTypeDetails = async (
    loan: LoanTypeDetailsModel,
    loanTypeId: string
  ) => {
    setLoading(true);
    try {
      const params = serialize(LoanTypeDetailsModel, loan);
      const endpoint = generatePath(ApiRoutes.LOAN_TYPE_DETAILS, {
        loanTypeId: loanTypeId,
      });
      const { data } = await axiosInstance.put(endpoint, {
        loan_type_detail: params,
      });

      const deserializedLoan = deserialize(
        LoanTypeDetailsModel,
        data["loan_type"]
      );

      return deserializedLoan;
    } catch (ex) {
      Notification({
        message: "Unable to Set Loan Type ",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to Set Loan Type ",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };
  const createLoanTypeCondition = async (
    loan: LoanTypeConditionModel,
    loanTypeId: string
  ) => {
    setLoading(true);
    try {
      const params = serialize(LoanTypeConditionModel, loan);
      const endpoint = generatePath(ApiRoutes.LOAN_TYPE_CONDITON, {
        loanTypeId: loanTypeId,
      });
      const { data } = await axiosInstance.post(endpoint, {
        loan_type_conditions: params,
      });

      const deserializedLoanConfig = deserialize(
        LoanTypeConditionModel,
        data["loan_type_condition"]
      );

      return deserializedLoanConfig;
    } catch (ex) {
      Notification({
        message: "Unable to Get Loan Type Condition",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to Get Loan Type Condition",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const getLoanTypeConfiguration = async (loanTypeId: string) => {
    setLoading(true);
    try {
      const endpoint = generatePath(ApiRoutes.LOAN_TYPE_CONFIGURATION, {
        loanTypeId: loanTypeId,
      });
      const { data } = await axiosInstance.get(endpoint);

      const deserializedLoanConfig = deserialize(
        LoanTypeConfigurationModel,
        data["loan_type"]
      );

      return deserializedLoanConfig;
    } catch (ex) {
      Notification({
        message: "Unable to Get Loan Type Configuration",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to Get Loan Type Configuration",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };
  const updateLoanTypeConfiguration = async (
    params: LoanTypeConfigurationModel,
    loanTypeId: string
  ) => {
    setLoading(true);
    try {
      const queryParams = params
        ? serialize(LoanTypeConfigurationModel, params)
        : {};

      const endpoint = generatePath(
        ApiRoutes.LOAN_TYPE_CONFIGURATION.replace(":loanTypeId", loanTypeId)
      );
      const { data } = await axiosInstance.put(endpoint, queryParams);

      const deserializedLoanConfig = deserialize(
        LoanTypeConfigurationModel,
        data["loan_type"]
      );
      Notification({
        message: "Loan Type Updated",
        description: "",
        type: NotificationTypes.SUCCESS,
      });

      return deserializedLoanConfig;
    } catch (ex) {
      Notification({
        message: "Unable to Get Loan Type Configuration",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to Get Loan Type Configuration",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const getRepaymentSchedule = async (
    loanId: string,
    params?: LoanRepayamentScheduleParams
  ) => {
    setLoading(true);
    try {
      const queryParams = params
        ? serialize(LoanRepayamentScheduleParams, params)
        : {};
      const endpoint = generatePath(ApiRoutes.LOANS_REPAYMENT_SCHEDULE, {
        loanId: String(loanId),
      });
      const { data } = await axiosInstance.get(endpoint, {
        params: queryParams,
      });
      const deserializedLoan = deserialize(
        LoanRepayamentSchedule,
        data["repayments"] as LoanRepayamentSchedule[]
      );
      const deserializedLoanMeta = deserialize(PaginationMeta, data?.meta);

      return {
        loans: deserializedLoan,
        meta: deserializedLoanMeta,
      };
    } catch (ex) {
      Notification({
        message: "Unable to Get Repayment Schedule",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to Get Repayment Schedule",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const getRepaymentEMISchedule = async (
    loanId: string,
    params?: LoanRepayamentScheduleParams
  ) => {
    setLoading(true);
    try {
      const queryParams = params
        ? serialize(LoanRepayamentScheduleParams, params)
        : {};

      const endpoint = generatePath(ApiRoutes.LOANS_EMI_REPAYMENT_SCHEDULE, {
        loanId: String(loanId),
      });
      const { data } = await axiosInstance.get(endpoint, {
        params: queryParams,
      });
      const deserializedLoan = deserialize(
        LoanRepayamentSchedule,
        data["repayments"] as LoanRepayamentSchedule[]
      );
      setLoanSchedule(deserializedLoan);
      const deserializedLoanMeta = deserialize(PaginationMeta, data?.meta);
      setRepaymentPagination(deserializedLoanMeta);

      return deserializedLoan;
    } catch (ex) {
      Notification({
        message: "Unable to Get Repayment Schedule",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to Get Repayment Schedule",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const getLoanTimeline = async (
    loanId: string,
    params?: LoanTimelineParams
  ) => {
    setLoading(true);
    try {
      const queryParams = params ? serialize(LoanTimelineParams, params) : {};
      const endpoint = generatePath(ApiRoutes.LOANS_TIMELINE, {
        loanId: String(loanId),
      });
      const { data } = await axiosInstance.get(endpoint, {
        params: queryParams,
      });
      const timelineData = deserialize(
        LoanTimeline,
        data["loan_timeline"] as LoanTimeline[]
      );
      const deserializedLoanMeta = deserialize(PaginationMeta, data?.meta);

      return {
        timeline: timelineData,
        meta: deserializedLoanMeta,
      };
    } catch (ex) {
      Notification({
        message: "Unable to Get Loan Timeline",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to Get Loan Timeline",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };
  const getLoanWallet = async (loanId: string, params?: LoanWalletParams) => {
    setLoading(true);
    try {
      const queryParams = params ? serialize(LoanWalletParams, params) : {};
      const endpoint = generatePath(ApiRoutes.LOANS_WALLET, {
        loanId: String(loanId),
      });
      const { data } = await axiosInstance.get(endpoint, {
        params: queryParams,
      });
      const deserializedLoan = deserialize(LoanWallet, data["wallet"]);
      const deserializedLoanMeta = deserialize(PaginationMeta, data?.meta);

      return {
        loans: deserializedLoan,
        meta: deserializedLoanMeta,
      };
    } catch (ex) {
      Notification({
        message: "Unable to Get Loan Wallet",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to Get Loan Wallet",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };
  const updatePreCloseLoan = async (loanId: string, amount: number) => {
    setLoading(true);
    try {
      const endpoint = generatePath(ApiRoutes.PRE_CLOSE, {
        loanId: String(loanId),
      });
      const { data } = await axiosInstance.post(endpoint, {
        pre_close: { amount: amount },
      });

      Notification({
        message: "Loan Pre Closed",
        type: NotificationTypes.SUCCESS,
      });

      return true;
    } catch (ex) {
      Notification({
        message: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to Update Pre Loan",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };
  const updateMoratoriumLoan = async (
    loanId: string,
    values: MoratoriumLoan
  ) => {
    setLoading(true);
    try {
      const endpoint = generatePath(ApiRoutes.MORATORIUM, {
        loanId: String(loanId),
      });
      const { data } = await axiosInstance.post(endpoint, {
        moratorium: values,
      });

      Notification({
        message: "Moratorium Updated",
        type: NotificationTypes.SUCCESS,
      });

      return true;
    } catch (ex) {
      Notification({
        message: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to update Moratorium ",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const updateWriteOffLoan = async (loanId: string, loan: WriteOffLoan) => {
    setLoading(true);
    try {
      const endpoint = generatePath(ApiRoutes.WRITE_OFF, {
        loanId: String(loanId),
      });
      const { data } = await axiosInstance.post(endpoint, {
        write_off: loan,
      });

      Notification({
        message: "Loan Written Off",
        type: NotificationTypes.SUCCESS,
      });

      return true;
    } catch (ex) {
      Notification({
        message: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to Update Loan Written Off",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const importLoan = async (attachment: FileAttachment) => {
    setLoading(true);
    try {
      const endpoint = generatePath(ApiRoutes.OFFLINE_REPORT);

      const { data } = await axiosInstance.post(endpoint, {
        attachment_id: attachment?.attachmentId,
      });

      Notification({
        message: data?.message,
        type: NotificationTypes.SUCCESS,
      });
      return true;
    } catch (ex) {
      Notification({
        message: "Unable to Import Loans",
        description: (ex as Error)?.message || "Unable to Import Loans",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const getLoanInstallment = async (
    params: LoanInstallmentParams,
    loanId: string
  ) => {
    setLoading(true);
    try {
      const endpoint = generatePath(ApiRoutes.LOAN_INSTALLMENT, {
        loanId: loanId,
      });
      const { data } = await axiosInstance.get(endpoint, { params });

      const deserializedInstallment = deserialize(
        LoanInstallmentSchedule,
        data["instalments"] as LoanInstallmentSchedule[]
      );

      const deserializedInstallmentMeta = deserialize(
        PaginationMeta,
        data?.meta
      );

      return {
        loan: deserializedInstallment,
        meta: deserializedInstallmentMeta,
      };
    } catch (ex) {
      Notification({
        message: "Unable to Get Loan Installment",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to Get Loan Installment",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const updateStatusCTVerification = async (loanFilter: DisbursementModel) => {
    setLoading(true);
    try {
      const params = serialize(DisbursementModel, loanFilter);
      const endpoint = generatePath(ApiRoutes.UPDATE_CT_VERIFICATION_STATUS);
      const { data } = await axiosInstance.put(endpoint, {
        loan: params,
      });
      Notification({
        message: "Loan Status Changed to CT Verification",
        type: NotificationTypes.SUCCESS,
      });
      return true;
    } catch (ex) {
      Notification({
        message: "Unable to Change Status",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to Change Status",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const getTransferLoan = async (params: TransferLoansParams) => {
    setLoading(true);
    try {
      const serializeParams = serialize(TransferLoansParams, params);
      const endpoint = generatePath(ApiRoutes.TRANSFER_LOAN);
      const { data } = await axiosInstance.get(endpoint, {
        params: serializeParams,
      });

      const loans = deserialize(
        TransferLoans,
        data["loans"] as TransferLoans[]
      );

      const meta = deserialize(PaginationMeta, data?.meta);

      return {
        loan: loans,
        meta: meta,
      };
    } catch (ex) {
      Notification({
        message: "Unable to Get Loans",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to Get Loans",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const transferLoan = async (params: TransferLoansParams) => {
    setLoading(true);
    try {
      const serializeParams = serialize(TransferLoansParams, params);
      const endpoint = generatePath(ApiRoutes.TRANSFER_LOAN);
      const { data } = await axiosInstance.put(endpoint, {
        transfer_loan: serializeParams,
      });
      Notification({
        message: "Loan Successfully Transfered",
        type: NotificationTypes.SUCCESS,
      });

      return true;
    } catch (ex) {
      Notification({
        message: "Unable to Transfer Loans",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to Transfer Loans",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const getLoanDeduction = async (loanTypeId: string) => {
    setLoading(true);
    try {
      const endpoint = generatePath(ApiRoutes.LOAN_DEDUCTION);
      const { data } = await axiosInstance.get(endpoint, {
        params: { id: loanTypeId },
      });
      const loanDeductionData = deserialize(
        LoanDeductionForm,
        data["deductions"] as LoanDeductionForm[]
      );

      return loanDeductionData;
    } catch (ex) {
      Notification({
        message: "Unable to get loan deduction",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to get loan deduction",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const addLoanDeduction = async (deductions: LoanDeductionForm) => {
    setLoading(true);
    try {
      const endpoint = generatePath(ApiRoutes.LOAN_DEDUCTION);
      const params = serialize(LoanDeductionForm, deductions);
      const { data } = await axiosInstance.post(endpoint, {
        deduction: params,
      });
      Notification({
        message: "Loan deduction added",

        type: NotificationTypes.SUCCESS,
      });
      return true;
    } catch (ex) {
      Notification({
        message: "Unable to add deduction",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to add deduction",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };
  const updateLoanDeduction = async (
    deductions: LoanDeductionForm,
    deductionId: string
  ) => {
    setLoading(true);
    try {
      const endpoint = generatePath(ApiRoutes.INDIVIDUAL_LOAN_DEDUCTION, {
        id: deductionId,
      });
      const params = serialize(LoanDeductionForm, deductions);
      const { data } = await axiosInstance.put(endpoint, {
        deduction: params,
      });
      Notification({
        message: "Loan deduction updated",

        type: NotificationTypes.SUCCESS,
      });
      return true;
    } catch (ex) {
      Notification({
        message: "Unable to update loan deduction",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to update loan deduction",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };
  const deleteLoanDeduction = async (deductionId: string) => {
    setLoading(true);
    try {
      const endpoint = generatePath(ApiRoutes.INDIVIDUAL_LOAN_DEDUCTION, {
        id: deductionId,
      });
      const { data } = await axiosInstance.delete(endpoint);
      Notification({
        message: "Loan deduction deleted",

        type: NotificationTypes.SUCCESS,
      });
      return true;
    } catch (ex) {
      Notification({
        message: "Unable to delete loan deduction",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to delete loan deduction",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const updateLoanTypeStatus = async (loanType: LoanTypeDetailsModel) => {
    setLoading(true);

    try {
      const endpoint = generatePath(ApiRoutes.UPDATE_LOAN_TYPE_STATUS, {
        id: loanType?.loanTypeId,
      });
      const { data } = await axiosInstance.put(endpoint, {
        loan_type: { is_active: !loanType?.isActive },
      });
      Notification({
        message: `Loan type ${loanType?.isActive ? "disabled" : "enabled"}`,
        type: NotificationTypes.SUCCESS,
      });
      return true;
    } catch (ex) {
      Notification({
        message: "Unable to change status",
        description: (ex as Error)?.message,
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const updateNameScoreMatch = async (loanId: string) => {
    setLoading(true);
    try {
      const endpoint = generatePath(ApiRoutes.UPDATE_NAME_MATCH_SCORE, {
        loanId: loanId.toString(),
      });

      const { data } = await axiosInstance.put(endpoint, {
        bank_detail: { is_approved_by_ho: true },
      });
      Notification({
        message: "Name matching verified",
        type: NotificationTypes.SUCCESS,
      });

      return true;
    } catch (ex) {
      Notification({
        message: "Unable to verify name matching",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to verify name matching ",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const nachImport = async (values: NachReport) => {
    setLoading(true);
    try {
      const params = serialize(NachReport, values);
      const { data } = await axiosInstance.post(ApiRoutes.NACH_IMPORT, params);
      Notification({
        message: "Nach report generation in progress",
        description: "",
        type: NotificationTypes.SUCCESS,
      });
      return true;
    } catch (ex) {
      Notification({
        message: "Unable to generate nach report",
        description: (ex as Error)?.message,
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const downloadNachTemplate = async (type: NachType) => {
    setTemplateLoading(true);

    try {
      const endpoint = generatePath(ApiRoutes.NACH_TEMPLATE);
      const response = await axiosInstance.get(endpoint, {
        params: { type },
        responseType: "arraybuffer",
      });
      downloadBlobAsFile(response, `${type}_template.xlsx`);
    } catch (ex) {
      Notification({
        message: "Unable to download report",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to download report",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setTemplateLoading(false);
    }
  };
  const generateReport = async (loanFilter: LoanReportParams) => {
    setLoading(true);
    try {
      const params = serialize(LoanReportParams, loanFilter);
      const endpoint = generatePath(ApiRoutes.GENERATE_REPORT);
      const { data } = await axiosInstance.get(endpoint, { params });
      Notification({
        message: "Report generating in progress",
        description: "File will auto download",
        type: NotificationTypes.SUCCESS,
      });
      return true;
    } catch (ex) {
      Notification({
        message: "Unable to generate report",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to generate report",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };
  const generateLoanReportCard = async (id: string) => {
    setTemplateLoading(true);
    try {
      const endpoint = generatePath(ApiRoutes.GENERATE_LOAN_REPORT_CARD, {
        loanId: String(id),
      });
      const { data } = await axiosInstance.get(endpoint);
      Notification({
        message: "Report generating in progress",
        type: NotificationTypes.SUCCESS,
      });
      return true;
    } catch (ex) {
      Notification({
        message: "Unable to generate report",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to generate report",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setTemplateLoading(false);
    }
  };

  const getLoanEMIDetails = async (loanId: string) => {
    setLoading(true);
    try {
      const endpoint = generatePath(ApiRoutes.LOAN_EMI_DETAILS, {
        loanId: loanId,
      });
      const { data } = await axiosInstance.get(endpoint);

      return deserialize(LoanEMI, data);
    } catch (ex) {
      Notification({
        message: "Unable to Get Loans",
        description: (ex as Error)?.message,

        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const loanPayEMI = async (loanFilter: LoanEMIParams) => {
    setLoading(true);
    try {
      const endpoint = generatePath(ApiRoutes.LOAN_PAY_EMI);
      const params = serialize(LoanEMIParams, loanFilter);
      const { data } = await axiosInstance.post(endpoint, { emi: params });

      return deserialize(LoanEMI, data);
    } catch (ex) {
      Notification({
        message: (ex as Error)?.message,
        description: "Unable to process request",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const confirmDuePayment = async (loanFilter: LoanEMIParams) => {
    setLoading(true);
    try {
      const endpoint = generatePath(ApiRoutes.CONFIRM_DUE_PAYMENT);
      const params = serialize(LoanEMIParams, loanFilter);
      const { data } = await axiosInstance.post(endpoint, params);
      Notification({
        message: "Amount has been credited successfully",
        type: NotificationTypes.SUCCESS,
      });

      return true;
    } catch (ex) {
      Notification({
        message: (ex as Error)?.message,
        description: "Unable to process request",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };
  
  const getTotalLoanAmount = async (query?: LoanParams) => {
    setLoading(true);
    try {
      const params = query ? serialize(LoanParams, query) : {};

      const endpoint = generatePath(ApiRoutes.TOTAL_LOANS_AMOUNT);
      const { data } = await axiosInstance.get(endpoint, {
        params
      });

      const deserializedLoanType = deserialize(
        Loan,
        data
      );
      return deserializedLoanType;
    } catch (ex) {
      Notification({
        message: "Unable to Get Loan Amount",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to Get Loan Amount",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const changePreferredChannel = async (loanIds: ChangePreferredChannel) => {
    setLoading(true);
    try {
      const endpoint = generatePath(ApiRoutes.CHANGE_PREFERRED_CHANNEL_DISBURSEMENT);
      const serializedLoanIds = serialize(ChangePreferredChannel, loanIds);
      const { data } = await axiosInstance.put(endpoint, {
        loans: serializedLoanIds
      });
      Notification({
        message: "Changed Preferred Channel Successfully",
        description: "",
        type: NotificationTypes.SUCCESS,
      });
      return true
    } catch (ex) {
      Notification({
        message: "Unable to Change Preferred Channel",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to Change Preferred Channel",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  }

  const loanDocumentsUpload = async (loanId: number, loanDocuments: UgroLoanDocument[]) => {
    setLoading(true);
    try {
      const endpoint = generatePath(ApiRoutes.LOAN_DOCUMENTS_UPLOAD, {
        loanId: loanId
      })
      const serializedDocuments = serialize(UgroLoanDocuments, { documents: loanDocuments });
      const { data } = await axiosInstance.post(endpoint, serializedDocuments)
      Notification({
        message: "Loan Documents Uploaded Successfully",
        description: "",
        type: NotificationTypes.SUCCESS,
      });
      return true
    } catch (ex) {
      Notification({
        message: "Unable to Upload Loan Documents",
        description: (ex as Error)?.message
          ? (ex as Error)?.message
          : "Unable to Upload Loan Documents",
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
    }

    const loanAgreementUpload = async (loanAgreement: LoanAgreement) => {
      setLoading(true)
      try {
        const endpoint = generatePath(ApiRoutes.LOAN_AGREEMENT_UPLOAD)
        const serializedLoanAgreement = serialize(LoanAgreement, loanAgreement);
        const response = await axiosInstance.post(endpoint, {
          loan_agreement: serializedLoanAgreement
        })
        const deserializedLoanAgreement = deserialize(LoanAgreement, response)
        Notification({
          message: "Loan Agreement Upload in Progress...",
          type: NotificationTypes.SUCCESS,
        });
        return deserializedLoanAgreement
      } catch (ex) {
        Notification({
          message: "Unable to upload Loan Agreement",
          description: (ex as Error)?.message
            ? (ex as Error)?.message
            : "Unable to upload Loan Agreement",
          type: NotificationTypes.ERROR
        })
      }
    }

    const getCrifReport = async (params: CreditReportParams) => {
      setLoading(true);
      try {
        const queryParams = params ? serialize(CreditReportParams, params) : {};
        const endpoint = generatePath(ApiRoutes.CRIF_REPORT)
        const { data } = await axiosInstance.get(endpoint, {
          params: queryParams
        });
        const deserializedReport = deserialize(CrifReport, data?.crif_report)
        if (deserializedReport.status === CrifFetchStatus.FAILED) {
          Notification({
            message: "Unable to get CRIF report",
            description: deserializedReport.message,
            type: NotificationTypes.ERROR
          })
        }
        return deserializedReport;
      } catch (ex) {
        Notification({
          message: "Unable to get CRIF report",
          description: (ex as Error)?.message
            ? (ex as Error)?.message
            : "Unable to get CRIF report",
          type: NotificationTypes.ERROR,
        });
        setError(ex as Error);
      } finally {
        setLoading(false);
      }
    }

    const addHypothecator = async (hypothecator: Hypothecator) => {
      setLoading(true);
      try {
        const serializedHypothecator = serialize(Hypothecator, hypothecator)
        const { data } = await axiosInstance.post(ApiRoutes.GET_HYPOTHECATORS, {
          hypothecator: serializedHypothecator
        })
        const newHypothecator = deserialize(Hypothecator, data.hypothecator)
        Notification({
          message: "Hypothecator added Successfully",
          type: NotificationTypes.SUCCESS
        })
        return newHypothecator
      } catch (ex) {
        Notification({
          message: "Error adding New Hypothecator",
          description: (ex as Error)?.message,
          type: NotificationTypes.ERROR
        })
        setError(ex as Error)
      } finally {
        setLoading(false)
      }
    }
  
  const updateLoansWithHypothecator = async (
    hypothecatedLoans: HypothecatorLoans
  ) => {
    setLoading(true);
    try {
      const endpoint = generatePath(ApiRoutes.UPDATE_LOANS_WITH_HYPOTHECATOR);
      const serializedHypothecatedLoans = serialize(
        HypothecatorLoans,
        hypothecatedLoans
      );
       const response = await axiosInstance.put(endpoint, {
        book_debt: serializedHypothecatedLoans,
      });
      Notification({
        message: "Book Debt Initiated for the Selected Loans",
        description: response.data.message,
        type: NotificationTypes.SUCCESS,
      });
      return true
    } catch (ex) {
      Notification({
        message: "Book Debt Initialization Failed",
        description: (ex as Error).message,
        type: NotificationTypes.ERROR
      })
    } finally {
      setLoading(false)
    }
  };
    const getRepaymentMonths = async (loanId: number | undefined, loanAmount: number | undefined) => {
      setLoading(true);
      try {
        const endpoint = generatePath(ApiRoutes.LOANS_REPAYMENT_TENURE_MONTHS, {
          loanId: loanId,
      })
      const { data } = await axiosInstance.get(endpoint, {
       params: {
        amount: loanAmount
       }
      });
      return data.applicable_tenures
     }
       catch (ex) {
        Notification({
          message: "Unable to Get Repayment Months",
          description: (ex as Error)?.message
            ? (ex as Error)?.message
            : "Unable to Get Repayment Months",
          type: NotificationTypes.ERROR,
        });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
    }
    const verifyDocuments = async (loanId: number, status: boolean) => {
      setLoading(true);
      try {
        const endpoint = generatePath(ApiRoutes.LOAN_DETAIL, {
          loanId: loanId
        })
        const { data } = await axiosInstance.put(endpoint, {
          mod_documents_reviewed: status
        });
        const verificationStatus = data.loan.mod_documents_reviewed
        Notification({
          message: verificationStatus ? "Documents Reviewed Successfully" : "Documents Not Reviewed",
          description: "",
          type: verificationStatus ? NotificationTypes.SUCCESS : NotificationTypes.ERROR,
        });
        return true
      } catch (ex) {
        Notification({
          message: "Unable to Review Documents",
          description: (ex as Error)?.message
            ? (ex as Error)?.message
            : "Unable to Review MOD Documents",
          type: NotificationTypes.ERROR,
        });
        setError(ex as Error);
      } finally {
        setLoading(false);
      }
    }
  
  return {
    getLoans,
    getLoan,
    getRepaymentSchedule,
    getLoanTimeline,
    getLoanWallet,
    updateLoan,
    loading,
    error,
    approveLoan,
    rejectLoan,
    loanLoading,
    generateLoanReport,
    disburseLoanAmount,
    initiateDisburseStatus,
    completeDisburseStatus,
    revertDisburseStatus,
    getLoanTypeDetails,
    getLoanTypeCondition,
    getLoanTypeConfiguration,
    updateLoanTypeConfiguration,
    createLoanTypeCondition,
    createLoanTypeDetails,
    updateLoanTypeDetails,
    updateLoanTypeCondition,
    deleteLoanTypeCondition,
    updatePreCloseLoan,
    updateWriteOffLoan,
    importLoan,
    updateMoratoriumLoan,
    getLoanInstallment,
    updateStatusCTVerification,
    getTransferLoan,
    transferLoan,
    getLoanDeduction,
    addLoanDeduction,
    updateLoanDeduction,
    deleteLoanDeduction,
    updateLoanTypeStatus,
    updateNameScoreMatch,
    nachImport,
    downloadNachTemplate,
    templateLoading,
    generateReport,
    generateLoanReportCard,
    getLoanEMIDetails,
    getRepaymentEMISchedule,
    loanPayEMI,
    repaymentPagination,
    loanSchedule,
    confirmDuePayment,
    getTotalLoanAmount,
    changePreferredChannel,
    loanDocumentsUpload,
    loanAgreementUpload,
    getCrifReport,
    getRepaymentMonths,
    addHypothecator,
    updateLoansWithHypothecator,
    verifyDocuments
  };
};
