export const singleValueQuery = [
  "page",
  "searchText",
  "stepNo",
  "sortBy",
  "sortDirection",
  "toDate",
  "fromDate",
  "paidDate",
  "approvedFromDate",
  "approvedToDate",
  "submittedFromDate",
  "submittedToDate",
  "securityType",
  "disbursementFromDate",
  "disbursementToDate",
  "isHypothecator",
  "maximumLoanAmount",
  "minimumLoanAmount",
  "totalAmount",
  "disbursementDate",
];
